"use client";

import Link from "next/link";
import { useState } from "react";

import Logo from "../icons/Logo";
import X from "../icons/X";
import Instagram from "../icons/Instagram";
import Youtube from "../icons/Youtube";
import { Button, ButtonLink } from "../ui/button";
import ChevronFill from "../icons/ChevronFill";
import { cn } from "@/lib/utils";
import { Facebook, Linkedin } from "lucide-react";
import { useStore } from "@/store";
import Arrow from "../icons/Arrow";

const Footer = ({ settings }: any) => {
  const setCookiesPreferences = useStore((state) => state.setCookiesPreferences);
  
  if (!settings?.data?.attributes) return null;


  const menuData = settings.data.attributes.websiteFooter;
  const firstMenu = menuData?.menus[0];
  const secondMenu = menuData?.menus[1];
  const thirdMenu = menuData?.menus[2];

  const socials = settings.data.attributes.Socials;

  const renderSocialIcon = (icon: string) => {
    switch (icon) {
      case "Instagram":
        return <Instagram color="white" className="h-8 w-8" />;
      case "Youtube":
        return <Youtube color="white" className="h-8 w-8" />;
      case "Twitter":
        return <X color="white" className="h-8 w-8" />;
      case "Facebook":
        return <Facebook color="white" className="h-8 w-8 fill-white stroke-none" />;
      case "LinkedIn":
        return <Linkedin color="white" className="h-8 w-8 fill-white stroke-none" />;
      default:
        return null;
    }
  };

  return (
    <footer className="relative z-[100] bg-dark-blue pb-[72px] pt-[72px] text-white md:pb-[60px] md:pt-[100px]">
      <div className="grid-container container">
        <div className="col-span-12 mb-[62px] md:col-span-2 md:mb-0">
          <Logo color="white" />
        </div>
        <div className="col-span-12 mb-8 md:col-span-3 md:mb-0">
          <nav>
            <ul className="flex flex-col gap-4">
              {/* {firstMenu?.items.map((item: any, index: number) => {
                return item.type === "wrapper_element" ? (
                  <NestedItem item={item} key={item.url} />
                ) : (
                  <li key={item.url} className="leading-0 font-oswald text-base font-bold uppercase md:hidden">
                    <Link href={item.url}>{item.title}</Link>
                  </li>
                );
              })} */}
              {firstMenu?.items[0].nestedItems.map((item: any, index: number) => (
                <li key={item.url} className="leading-0 font-oswald text-base font-bold uppercase md:block">
                  <Link href={item.url}>{item.title}</Link>
                </li>
              ))}
            </ul>
          </nav>
        </div>
        <div className="col-span-12 md:col-span-3">
          <div className="flex flex-col gap-y-6 md:gap-y-14 justify-between md:gap-14">
            <nav>
              <ul className="mb-4 flex flex-col gap-4 md:mb-0">
                {secondMenu?.items.map((item: any, index: number) => {
                  return (
                    <li key={item.url} className="leading-0 font-oswald text-base font-bold uppercase">
                      <Link href={item.url}>{item.title}</Link>
                    </li>
                  );
                })}
              </ul>
            </nav>
            <nav>
              <ul className="flex flex-col gap-4">
                {thirdMenu?.items.map((item: any, index: number) => {
                  return (
                    <li key={item.url} className="leading-0 font-oswald text-base font-bold uppercase">
                      <Link href={item.url}>{item.title}</Link>
                    </li>
                  );
                })}
              </ul>
            </nav>
          </div>
        </div>
        <div className="col-span-12 mt-[48px] md:col-span-3 md:mt-0">
          <div className="flex flex-col">
            <div className="flex items-center gap-6">
              {socials.map((social: any) => {
                return (
                  <Link href={social.url} key={social.id} target="_blank" rel="noopener noreferrer">
                    <span className="sr-only">{social.title}</span>
                    {renderSocialIcon(social.socialNetwork)}
                  </Link>
                );
              })}
            </div>
            <ButtonLink href="/lid-worden" variant="default" size="default" className="mt-11 w-full" fullWidth>
              Lid worden
            </ButtonLink>
            <div className="mt-16">
              <p
                className="whitespace-pre font-roboto text-pretty font-light leading-7"
                dangerouslySetInnerHTML={{ __html: menuData?.information }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="grid-container container mt-12 md:mt-24">
        <div className="col-span-12 md:col-span-3 md:col-start-3">
          <ButtonLink arrowColor="white" href="/privacy-statement">
            Bekijk onze Privacy statement
          </ButtonLink>
        </div>
        <div className="col-span-12 md:col-span-3">
          <ButtonLink arrowColor="white" href="/anbi">
            Bekijk onze ANBI-status
          </ButtonLink>
        </div>
        <div className="col-span-12 md:col-span-3">
          <Button variant="link" className="p-0 h-full group" onClick={() => setCookiesPreferences(true)}>
            Cookie voorkeuren
            <Arrow color="white" className="ml-3 min-w-fit transition-transform group-hover:translate-x-1" />
          </Button>
        </div>
      </div>
    </footer>
  );
};

const NestedItem = ({ item }: { item: any }) => {
  const [open, setOpen] = useState(false);

  return (
    <li key={item.url} className="leading-0 font-oswald text-base font-bold uppercase md:hidden">
      <button
        onClick={() => {
          setOpen(!open);
        }}
        className="flex items-center uppercase"
      >
        {item.title}{" "}
        <ChevronFill
          className={cn("ml-2 transition-transform", open ? "!rotate-0" : "!rotate-180")}
          color="white"
          direction="down"
        />
      </button>
      {open && item.nestedItems && item.nestedItems.length > 0 && (
        <ul className="mt-4 flex list-none flex-col gap-4 pl-8 text-white">
          {item.nestedItems.map((item: any, index: number) => {
            return (
              <li key={item.url} className="leading-0 font-oswald text-base font-bold uppercase">
                <Link href={item.url}>{item.title}</Link>
              </li>
            );
          })}
        </ul>
      )}
    </li>
  );
};

export default Footer;
