import { MotionConfig, motion } from "framer-motion";

import { Button } from "./button";

const Hamburger = ({ open, setOpen }: { open: boolean; setOpen: React.Dispatch<React.SetStateAction<boolean>> }) => {
  return (
    <MotionConfig
      transition={{
        duration: 0.3,
        ease: [0.16, 1, 0.3, 1],
      }}
    >
      <Button
        aria-label={open ? "Menu sluiten" : "Menu openen"}
        onClick={() => setOpen(!open)}
        variant="ghost"
        className="ml-auto flex h-auto w-fit max-w-[65px] flex-col gap-1.5 py-0 lg:hidden"
      >
        <motion.span animate={{ rotateZ: open ? -45 : 0, y: open ? 9 : 1 }} className="h-[3px] w-full bg-white" />
        <motion.span animate={{ opacity: open ? 0 : 1, x: open ? 1 : 0 }} className="h-[3px] w-full bg-white" />
        <motion.span animate={{ rotateZ: open ? 45 : 0, y: open ? -9 : 0 }} className="h-[3px] w-full bg-white" />
        <span className="font-roboto text-sm normal-case leading-none text-white">{!open ? "menu" : "sluiten"}</span>
      </Button>
    </MotionConfig>
  );
};

export { Hamburger };
